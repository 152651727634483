h3.ship-title {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
}
p.ship-tx {
    font-size: 14px;
    margin: 0;
}

div#orders-ship-wrapper {
    box-shadow: 0 0 10px 3px #ddd;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
}

div#orders-ship-wrapper .form-field {
    width: 25%;
}

div#orders-ship-wrapper .form-icon {
    width: 12.5%;
    text-align: center;
}

div#orders-ship-wrapper .ship-btn {
    text-align: right !important;
    padding: 20px 0;
}
h3.ship-title.top {
    padding: 25px 0 10px 0;
}

main.content {
    background: #f5f5f5;
}

