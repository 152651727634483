main.content {
    background: #f5f5f5;
}

#rate-main-wrapper {
    box-shadow: 0 0 10px 3px #ddd;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
}

#rate-main-wrapper .rate-btn {
    text-align: right;
    padding-bottom: 15px;
}

#rate-main-wrapper p.delivery-tx {
    font-size: 13px;
    color: #999999;
}

#rate-main-wrapper span.green-bg {
    background: #3C8C25;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    padding: 3px 8px;
    border-radius: 4px;
    margin-right: 10px;
}

#rate-main-wrapper h3.green-title {
    color: #008C04;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 4px;
}

h3.price-title {
    font-weight: 700;
    font-size: 30px;
    margin: 0;
    color: #333333;
}