@import url('https://fonts.googleapis.com/css2?family=Allison&family=Anton&family=Handlee&family=Herr+Von+Muellerhoff&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:wght@400;500;600;700&family=Merienda:wght@700&family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&family=Oswald:wght@200;300;400;500;600;700&family=Parisienne&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Shippori+Mincho:wght@400;500;600;700;800&family=Signika:wght@300;400;500;600;700&family=Special+Elite&family=Varela+Round&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body,
p,
a,
strong,
li,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Poppins';
}
section.login-first-section {
  padding: 70px 0;
}

.login-form {
  text-align: center;
  box-shadow: 0 0 15px 10px #eee;
  padding: 50px 50px 70px 50px;
  max-width: 52% !important;
  margin: 0 auto;
}

.google-btn a {
  border: 2px solid #eeedf8;
  padding: 15px 40px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.google-btn a img {
  height: 28px;
  margin-right: 10px;
}

h2.main-heading {
  font-size: 36px;
  font-weight: 600;
}

.google-btn {
  padding-top: 20px;
}

.login-top-img {
  position: absolute;
  top: 25%;
  width: max-content !important;
}

.login-right-img {
  position: absolute;
  right: 10%;
  text-align: right;
  bottom: 10%;
}

.img-avatar {
  height: 5rem;
  margin-bottom: 1rem;
}
.row.login-rw {
  padding: 50px 0;
}
input.is-invalid {
  border: 1px solid red !important;
}
a.register-btn {
  color: #806be5;
  text-decoration: none;
}

.login-error-form {
  text-align: left;
  padding: 10px 50px;
}

p,
label {
  color: #7e7b96;
  font-size: 16px;
}

label {
  font-weight: 500;
}

.login-error-form input,
.modal input {
  width: 100%;
  padding: 10px 10px 10px 50px;
  border-radius: 4px;
  border: 1px solid #dcdbe5;
  position: relative;
  margin-bottom: 18px;
  margin-top: 8px;
}

.icon-field {
  position: relative;
}

.icon-field i {
  position: absolute;
  left: 16px;
  bottom: 25px;
  color: #9b98b4;
}

.pass-remember {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

span.psw {
  text-decoration: underline;
}

span.psw a {
  color: #000;
}

.submit-btn button {
  background: #806be5;
  color: #fff;
  padding: 12px 40px;
  border: none;
  border-radius: 6px;
  width: 100%;
  min-width: 184px;
  font-size: 16px;
  font-weight: 500;
}
.sbmt-btn .outline-border {
  color: #806be5;
  background: #fff;
  padding: 12px 40px;
  border: 1px solid #806be5;
  border-radius: 6px;
  width: 100%;
  min-width: 184px;
  font-size: 16px;
  font-weight: 500;
}

.sbmt-btn .default-btn {
  background: #806be5;
  color: #fff;
  padding: 12px 40px;
  border: none;
  border-radius: 6px;
  width: 100%;
  min-width: 184px;
  font-size: 16px;
  font-weight: 500;
}

.dark-btn:hover,
.sbmt-btn .default-btn:hover {
  background: #8d8ba2;
  color: #fff !important;
}

.sbmt-btn .default-btn i {
  margin-left: 10px;
  top: 1px;
  position: relative;
  margin-left: 10px;
  font-size: 18px !important;
}

.submit-btn {
  margin-top: 12px;
}
.submit-btn button i {
  margin-left: 10px;
  top: 1px;
  position: relative;
}

.checkbox > input:checked {
  background-color: #806be5;
  border: 1px solid #806be5;
}

.checkbox > input {
  height: 21px;
  width: 21px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: 1px solid #ccc;
  transition-duration: 0.3s;
  background-color: #ffffff;
  cursor: pointer;
  padding: 0 !important;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 6px;
  top: 8px;
  font-size: 13px;
}

.checkbox > input:active {
  border: 2px solid #34495e;
}

.checkbox span {
  margin-left: 10px;
}

.create-half {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.create-half .form-field {
  width: 48%;
}

.modal-header.custom {
  flex-flow: column;
  border: none;
}

.modal .dark-btn {
  background: #806be5 !important;
  width: 100%;
  padding: 12px 30px !important;
  font-weight: 600;
  color: #fff !important;
}

.dark-btn:hover,
.submit-btn button:hover {
  background: #8d8ba2;
  color: #fff !important;
}

.modal-footer {
  border: none !important;
  padding-top: 0 !important;
  padding-bottom: 30px !important;
}
.form-field {
  position: relative;
}
.forgot button.psw {
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  font-weight: 600;
  color: #7e7b96;
}

p.login-error {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}

.eye-icon i {
  right: 15px !important;
  left: auto !important;
}

.layout {
  z-index: 1;
}

.header {
  box-shadow: 1px 1px 4px #9aa0b9;
  display: flex;
  align-items: center;
  padding: 20px;
}

.content {
  padding: 10px 19px;
  display: flex;
  flex-direction: column;
}

.footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px;
}

.image-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  z-index: 1;
  display: none;
}

.sidebar-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding: 30px 0 20px 0;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 0 20px 10px 20px;
}

.sidebar-content {
  flex-grow: 1;
  padding: 10px 0;
}

.layout,
.sidebar,
.menu,
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  color: #fff;
}

.menu-icon {
  font-size: 1.2rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  transition: color 0.3s;
}

.menu-title {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  transition: color 0.3s;
}
a {
  text-decoration: none !important;
}
.menu-prefix,
.menu-suffix {
  display: inline-block;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}

.sub-menu {
  position: relative;
}

.menu li a::after {
  content: '';
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 9px;
  height: 9px;
  transform: rotate(-45deg);
}
.sidebar ul li.menu-item {
  margin: 5px 0;
}

.sidebar ul li.menu-item.active,
.sidebar ul li.menu-item:hover {
  background: rgba(255, 255, 255, 0.4);
  color: #fff !important;
}

.sidebar ul li.menu-item.active a,
.sidebar ul li.menu-item:hover a {
  color: #fff !important;
}

.sub-menu-list {
  padding-left: 20px;
  display: none;
  overflow: hidden;
  z-index: 999;
}

.layout .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}

.layout .sidebar .menu .menu-item a .menu-title::after {
  display: none;
}

.collapsed .menu > ul,
.collapsed .menu-item a .menu-prefix,
.menu-suffix {
  opacity: 0;
}

.layout .sidebar .menu .menu-item.sub-menu {
  position: relative;
}

.layout
  .sidebar:not(.collapsed)
  .menu
  > ul
  > .menu-item.sub-menu
  > .sub-menu-list {
  visibility: visible !important;
  position: static !important;
  transform: translate(0, 0) !important;
}

.layout .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 20px;
  display: none !important;
  overflow: hidden;
  z-index: 999;
}

.sub-menu-list {
  transition: none !important;
  width: 200px;
  margin-left: 3px !important;
  border-radius: 4px;
  display: block !important;
}

aside#sidebar {
  background: #806be5;
  position: fixed;
}

.layout .sidebar .menu .menu-item.sub-menu.open .sub-menu-list {
  display: block !important;
}

.layout.header-bar {
  margin-left: 280px;
}
a#btn-collapse {
  display: none;
}

header.header {
  justify-content: flex-end;
  align-items: center;
  padding-right: 45px;
  background: #fff;
}
.sidebar .menu ul {
  padding-left: 0;
}

p.user-profile img {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  margin: 0 10px;
}

p.user-profile {
  margin: 0;
}
#user-profile-image {
  height: 100px;
  width: 100%;
  margin: 30px 0px;
  padding: 1px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#user-profile-image img {
  width: 19%;
  max-width: 400px;
  height: 117px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid #806be5;
  margin: 14px 30px;
  padding: 7px;
}
/*header css ends*/

body.main-body {
  height: 100%;
  background: #f2f2f2;
}

.ordermix-content {
  box-shadow: 0 0 10px 3px #ddd;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}

.ordermix-form label {
  color: #7e7b96;
  font-weight: 600;
  margin-bottom: 5px;
  text-transform: uppercase;
}

input {
  border: 2px solid #eeedf8;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px !important;
}

form.ordermix-form {
  padding: 30px 0;
  max-width: 70%;
  margin: 0 auto;
  border-bottom: 3px solid #eeedf8;
}

.ordermix-table {
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
}
tr > th,
tr > td {
  padding: 10px 12px;
}
table p {
  margin: 0;
}
.ordermix-table thead {
  background: linear-gradient(180deg, white, #f0f0f0);
}
p.order-error.invalid {
  font-size: 13px;
  color: #999999;
}

.submit-btn button {
  background: #806be5;
  color: #fff;
  padding: 12px 30px;
  border-radius: 5px;
  width: 100% !important;
  max-width: 100% !important;
  border: none;
  font-size: 16px;
  font-weight: 600;
}

.submit-btn button i {
  margin-left: 10px;
  font-size: 18px !important;
}

.google-button button {
  background: transparent;
  color: black;
  padding: 12px 30px;
  border-radius: 5px;
  width: 100% !important;
  max-width: 100% !important;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #00000030;
}



input:focus {
  outline: none !important;
}

.order-half {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.order-half .form-field {
  width: 48%;
}

tr.odd {
  background: #f9f9f9;
}

a.remove {
  color: #806be5 !important;
  font-size: 15px;
}

.modal-header.custom {
  flex-flow: column;
  border: none;
}

.order-modal .modal-content {
  text-align: center;
}

.order-modal .modal-content p {
  width: 100%;
  color: #7e7b96;
  font-size: 15px;
}

.order-modal h3 {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.modal-del i {
  background: #ffefef;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff4646;
  font-size: 20px;
  margin: 10px auto;
}

.modal-del {
  width: 100%;
}

button.btn.cancel-btn {
  background: #e5ece6;
  color: #9fb4a0;
  font-weight: 600;
}

button.btn.delete-btn {
  background: #ff4646;
  color: #fff;
  font-weight: 600;
}

.order-modal .modal-footer {
  justify-content: center;
}

#table {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

#google-login-img {
  margin-right: 1rem;
  height: 28px;
}

/* No order selected page */

.mt-100 {
  margin-top: 100px;
}

.card {
  margin-bottom: 30px;
  border: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
}

.card .card-header {
  background-color: #fff;
  border-bottom: none;
  padding: 24px;
  border-bottom: 1px solid #f6f7fb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card .card-body {
  padding: 30px;
  background-color: transparent;
}

.btn-primary,
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #4466f2 !important;
  border-color: #4466f2 !important;
}

a:hover{
  cursor: pointer;
}

.pointer {cursor: pointer;}



.retry-img img{
  height: 20px;
}
.retry-img img:hover{
  cursor: pointer;
}