
    .order-modal .modal-content {
        text-align: center;
      }
      .modal-header.custom {
        flex-flow: column;
        border: none;
      }
      .modal-del {
        width: 100%;
      }
      .modal-del i {
        background: #ffefef;
        height: 60px;
        width: 60px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff4646;
        font-size: 20px;
        margin: 10px auto;
      }
      .order-modal h3 {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        width: 100%;
      }
      .order-modal .modal-content p {
        width: 100%;
        color: #7e7b96;
        font-size: 15px;
      }
      .order-modal .modal-footer {
        justify-content: center;
      }
      button.btn.cancel-btn {
        background: #e5ece6;
        color: #9fb4a0;
        font-weight: 600;
      }
      button.btn.delete-btn {
        background: #ff4646;
        color: #fff;
        font-weight: 600;
      }
   