.details-btn
{
    text-align: right;
    padding: 20px 0;
}

div#shipdetails-main-wrapper {
    box-shadow: 0 0 10px 3px #ddd;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
}

#shipdetails-main-wrapper span.inner-title {
    color: #999999;
    font-size: 12px;
    font-weight: 500;
    padding-left: 10px;
}

#shipdetails-main-wrapper span.best-tit {
    color: #5DACF4;
    border-right: 1px solid #eee;
    padding: 0 5px;
    margin-right: 5px;
}

#shipdetails-main-wrapper .retail-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#shipdetails-main-wrapper  p.retail-tx {
    font-size: 14px;
    margin: 0;
}

#shipdetails-main-wrapper p.delivery-tx.shipment {
    margin-bottom: 5px;
}
#shipdetails-main-wrapper span.green-bg {
    background: #3C8C25;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    padding: 3px 8px;
    border-radius: 4px;
    margin-right: 10px;
}

#shipdetails-main-wrapper p.delivery-tx {
    font-size: 13px;
    color: #999999;
}

p.pay-details {
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
}

span.dark-text {
    color: #5DACF4;
}
